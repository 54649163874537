import * as React from "react";
import Colors from "../colors";
import { css, jsx } from '@emotion/react';
import ReactPaginate from 'react-paginate';
import projectData from '../../content/projects.yaml';
import chroma from "chroma-js";
import '../pagination.less';

const projectCardWidth = 200;
const projectCardMarginRight = 16;

const ProjectCard = ({ project, skillColors, onClickSkill }) => {
  return (
    <div
      css={css`
        border-radius: 6px;
        border: 2px solid ${Colors.violet};
        padding: 16px;
        margin-right: ${projectCardMarginRight}px;
        width: ${projectCardWidth}px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
      `}
    >
      <a
        target="_blank"
        rel="noreferrer noopener"
        css={css`
            color: ${Colors.violet};
            &:hover {
              text-decoration: none;
            }
        `}
        href={project.url}
      >
        <h4 css={css`
            margin-top: 0;
            margin-bottom: 1em;
          `}
        >
            {project.title}
        </h4>
      </a>
      <p css={css`
        font-size: 16px !important;
      `}>
        {project.description}
      </p>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          flex-grow: 1;
        `}
      >
        <span
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
          `}
        >
          {project.skills.map(s =>
            <span key={s}
              css={css`
                border-radius: 2px;
                font-size: 85%;
                padding: 3px 6px;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                color: ${skillColors[s]};
                background-color: ${chroma(skillColors[s]).alpha(0.1).css()};
                margin-left: 4px;
                cursor: pointer;
                margin-bottom: 6px;
              `}
              onClick={() => onClickSkill && onClickSkill(s)}
            >
              {s}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

class ProjectListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedPage: 0,
    };
  }

  render() {
    let skillsFilter, numberOfResultsPerPage, skillColors, onClickSkill;

    ({ skillsFilter, numberOfResultsPerPage, skillColors, onClickSkill } = this.props);
    numberOfResultsPerPage = numberOfResultsPerPage || 12;

    let foundProjects = projectData;
    if (skillsFilter && skillsFilter.length) {
      foundProjects = foundProjects.filter(project => project.skills.some(s => skillsFilter.includes(s)));
    }

    const pageCount = Math.ceil(foundProjects.length / numberOfResultsPerPage);

    foundProjects.sort((lhs, rhs) => {
      if (lhs.date === rhs.date) {
        return 0;
      } else if (lhs.date < rhs.date) {
        return 1;
      } else {
        return -1;
      }
    });

    const offset = this.state.displayedPage * numberOfResultsPerPage;
    foundProjects = foundProjects.slice(offset, offset + numberOfResultsPerPage);

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div css={css`
          display: inline-flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        `}>
          {foundProjects.map(p => <ProjectCard key={p.title} project={p} skillColors={skillColors} onClickSkill={onClickSkill}/>)}
        </div>

        {projectData.length > numberOfResultsPerPage && <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPgesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => this.setState({ displayedPage: data.selected })}
          containerClassName={'projectPagination'}
        />}
      </div>
    );
  }
}

export default ProjectListComponent;
