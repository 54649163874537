import * as React from "react"
import { css, jsx } from '@emotion/react';
import '../base.css';
import Layout from '../components/Layout';
import ProjectList from '../components/ProjectList';
import ProjectSearch from '../components/ProjectSearch';
import Colors from "../colors";
import projectData from "../../content/projects.yaml";

// TODO: cross browser check for design (including mobile)

const sectionCss = css`
  margin-top: 64px;
  padding: 0 32px;

  p {
    font-size: 18px;

    @media screen and (min-width: 40em) {
      font-size: 24px;
    }
  }
`;

const sectionTitle = css`
  font-family: Pacifico, Roboto, sans-serif;
  font-size: 32px;
`;

const resumeContainerCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const resumeCss = css`
  min-width: 128px;
  border-radius: 6px;
  background-color: ${Colors.violet};
  padding: 16px;
  color: '#fff',
`;

const resumeTitle = css`
  margin: 0;
  color: #fff;
  text-align: center;
`;

const RESUMES = [
  ['/resumes/dizzy.faefyre.pdf', 'General Resume'],
  ['/resumes/dizzy.faefyre.frontend.pdf', 'Frontend Resume'],
  ['/resumes/dizzy.faefyre.backend.pdf', 'Backend Resume'],
  ['/resumes/dizzy.faefyre.sdet.pdf', 'SDET Resume'],
  ['/resumes/dizzy.faefyre.devops.pdf', 'DevOps Resume'],
];

// markup
class IndexPage extends React.Component {
  constructor() {
    super();

    this.state = {
      skillsFilter: [],
    }

    let skills = [];
    projectData.forEach(project => {
      skills.push(...project.skills);
    });
    skills = [...new Set(skills)];

    this.skills = skills;

    const gen = selectColors();

    const skillColors = {};
    skills.forEach(s => skillColors[s] = gen.next().value);

    this.skillColors = skillColors;

    function* selectColors() {
      let index = 0;

      while (true) {
        index = (index + 1) % Colors.multiSelectColors.length;
        yield Colors.multiSelectColors[index];
      }
    }
  }

  render() {
    return (
      <Layout>
        <a id="experience" css={css`
          position: relative;
          top: -10px;
        `}/>

        <section css={sectionCss}>
          <h1 css={sectionTitle}>Experience</h1>

          <p>Over the past 10+ years I've been able to work professionally with many different technologies, platforms
            and frameworks, in all areas of the SDLC.</p>

          <p>Below are links to my resumes detailing this experience. As a generalist, my skills cover many different
            areas and types of positions, so I have more
            than one resume, each focusing on a different position:</p>

          <div id="resumes" css={resumeContainerCss}>
            {RESUMES.map(([resumeUrl, resumeName]) => {
              return (
                <a href={resumeUrl} key={resumeUrl} rel="noreferrer noopener" target="_blank" css={css`
                  text-decoration: none;
                  margin-right: 32px;
                  display: 'inline-block';
                  margin-bottom: 16px;
                  &:last-child {
                    margin-right: 0;
                  }
                `}>
                  <div css={resumeCss}>
                    <h4 css={resumeTitle}>{resumeName}</h4>
                  </div>
                </a>
              );
            })}
          </div>
        </section>

        <a id="projects" css={css`
          position: relative;
          top: -10px;
        `}/>

        <section css={sectionCss}>
          <h1 css={sectionTitle}>Personal Projects</h1>

          <ProjectSearch
            allSkills={this.skills}
            selectedSkills={this.state.skillsFilter}
            skillColors={this.skillColors}
            onChange={skills => this.setState({ skillsFilter: skills })}
          />
          <ProjectList
            skillsFilter={this.state.skillsFilter}
            skillColors={this.skillColors}
            onClickSkill={(s) => this.setState({ skillsFilter: [s] })}
          />
        </section>
      </Layout>
    )
  }
}

export default IndexPage
