import * as React from "react";
import Colors from "../colors";
import { css, jsx } from '@emotion/react';
import Select from 'react-select'
import chroma from 'chroma-js';

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: isFocused ? Colors.violet + ' !important' : 'hsl(0, 0%, 80%)',
    boxShadow: isFocused ? '0 0 0 1px ' + Colors.violet : undefined,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const ProjectSearchComponent = ({ onChange, selectedSkills, allSkills, skillColors }) => {
  const options = allSkills.map(skill => ({ value: skill, label: skill, color: skillColors[skill] }));
  const selectedOptions = selectedSkills.map(s => {
    return options.find(sv => sv.value === s);
  });

  return (
    <div css={css`
      margin-bottom: 32px;
    `}>
      <Select
        options={options}
        isMulti={true}
        hasValue={true}
        value={selectedOptions}
        onChange={value => onChange(value.map(x => x.value))}
        styles={colourStyles}
        placeholder={"Filter..."}
      />
    </div>
  );
};

export default ProjectSearchComponent;